<template>
  <div
    class="AmountTypeItem"
    :class="{ HasAmount: hasAmount }">
    <div class="ImageWrap">
      <MultiImage
        v-if="type.images[0]"
        :image="type.images[0]"
        transformation="square_80" />
    </div>

    <div class="InfoWrap">
      <span class="Title">{{ type.getTitle({details: true}) }}</span>
      <div class="Row">
        <div class="IconWrap">
          <HouseIcon />
        </div>
        <span>{{ type.getUnitsList() }}</span>
      </div>
      <div
        class="Row"
        v-if="type.getPlacesList()">
        <div class="IconWrap SmallerIcon">
          <RoomsIcon />
        </div>
        <span>{{ type.getPlacesList() }}</span>
      </div>
      <div
        class="Row"
        v-if="type.getAmount()">
        <div class="IconWrap SmallerIcon">
          <AmountIcon />
        </div>
        <span>{{ type.getAmount() }}</span>
      </div>
    </div>

    <!-- Right side -->
    <div class="AmountIconWrap">
      <div
        class="IconWrap"
        @click="onEditAmountClick">
        <AmountIcon />
      </div>
    </div>
  </div>
</template>

<script>
import HouseIcon from '@/assets/svg/house.svg?inline'
import RoomsIcon from '@/assets/svg/rooms.svg?inline'
import AmountIcon from '@/assets/svg/amount.svg?inline'
import MultiImage from '@/components/Images/MultiImage.vue'

export default {
  name: 'AmountTypeItem',
  props: {
    type: {
      type: Object,
      required: true,
    },
    hasAmount: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onEditAmountClick() {
      this.$emit('edit-amount', this.type)
    },
  },
  components: {
    HouseIcon,
    RoomsIcon,
    MultiImage,
    AmountIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .AmountTypeItem
    position relative
    display flex
    align-items stretch
    background-color $color_grey_lightest
    margin-bottom 5px
    padding 5px
    padding-right 0
    &:last-child
      margin-bottom 0
    &.HasAmount
      background-color lighten($color_primary_lightest, 50%)

  .ImageWrap
    position relative
    flex-shrink 0
    box(70px)
    margin-right 5px

  .InfoWrap
    flex-grow 2
    min-width 1px
    span
      truncated()
    .Title
      font-size 0.875rem
      text-transform uppercase
      color $color_grey_dark
    .Row
      display flex
      align-items center
      margin-bottom 2px
      &:last-child
        margin-bottom 0
      .IconWrap
        box(14px)
        flex-center-children()
        margin-right 5px
        flex-shrink 0
        svg
          fill $color_grey
          flex-shrink 0
        &.SmallerIcon
          padding 1px
      span
        font-size 0.75rem
        padding-top 2px
        height 14px
        line-height 1

  .AmountIconWrap
    flex-shrink 0
    flex-center-children()
    margin-right 10px
    .IconWrap
      box(40px)
      padding 7.5px
      border-radius 3px
      background-color $color_primary
      box-shadow $box_shadow_1
      cursor pointer
      svg
        fill #fff
</style>
