<template>
  <div class="AmountsPageHeader">
    <div class="InnerWrap">
      <CloseButton />

      <div class="TitleWrap">
        <span class="PageTitle">
          {{ mixWB('AMOUNTS') }} ({{ this.amountStatus.done }}/{{ this.amountStatus.total }})
        </span>
        <SingleUnitSelectedText />
      </div>

    </div>

    <TabNavigation
      :navigationItems="navigationItems"
      width="1000" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { screeningGroupedTypes } from '@/globals/javascript/_util/grouped-types'
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import TabNavigation from '@/components/TabNavigation.vue'
import SingleUnitSelectedText from '@/components/SingleUnitSelectedText.vue'

export default {
  name: 'AmountsPageHeader',
  computed: {
    ...mapGetters([
      'amountStatus',
      'currentScreening',
      'screeningInterConnections',
      'screeningTypes',
      'currentScreeningSelectedFilterUnitID',
    ]),
    navigationItems() {
      return [
        {
          title: this.mixWB('TYPES'),
          pathName: 'ScreeningsUpdateAmounts',
          params: { screeningID: this.currentScreening.id },
          isDisabled: false,
        },
        {
          title: this.mixWB('INTERCONNECTIONS'),
          pathName: 'ScreeningsUpdateAmountsInterconnections',
          params: { screeningID: this.currentScreening.id },
          isDisabled: !this.groupedTypes.length,
        },
      ]
    },
    groupedTypes() {
      const allGroupedTypes = screeningGroupedTypes({
        interConnections: this.screeningInterConnections,
        screeningTypes: this.screeningTypes,
        unitID: this.currentScreeningSelectedFilterUnitID,
      })

      return allGroupedTypes.filter((x) => x.options.isAmountRequired)
    },
  },
  components: {
    CloseButton,
    TabNavigation,
    SingleUnitSelectedText,
  },

}
</script>

<style lang="stylus" scoped>
  .AmountsPageHeader
    display block
    border-bottom 1px solid $color_grey_lighter
    margin-left -20px
    margin-right -20px
    margin-bottom 20px
    padding-left 20px
    padding-right 20px

  .InnerWrap
    position relative
    max-width 1000px
    margin 0 auto
    >>> .CloseButton
      top -23px
      right -15px
      +below($tablet)
        top -18px

  .TitleWrap
    margin-bottom 20px
    .PageTitle
      display block
      font-size 1.125rem
      font-weight bold
      text-transform uppercase
</style>
