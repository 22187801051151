<template>
  <div class="AmountsModal">
    <span class="Title">{{ mixWB('SPECIFY_AMOUNT') }}</span>

    <!-- Image -->
    <div class="ImageWrap">
      <MultiImage
        :image="type.images[0]"
        transformation="square_200" />
    </div>

    <!-- Amounts -->
    <AmountsSelect
      :isActive="true"
      :screeningType="type"
      :isAmountPage="true"
      @save="onAmountSave" />
  </div>
</template>

<script>
import AmountsSelect from '@/components/ScreeningTypeSections/AmountsSelect.vue'
import EventBus from '@/EventBus'
import MultiImage from '@/components/Images/MultiImage.vue'

export default {
  name: 'AmountsModal',
  props: {
    type: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onAmountSave(amounts) {
      const typeCopy = JSON.parse(JSON.stringify(this.type))
      typeCopy.amounts = amounts

      this.$store.dispatch('setScreeningType', {
        type: typeCopy,
      })

      this.$emit('close-dialog')
    },
  },
  components: {
    AmountsSelect,
    MultiImage,
  },
  mounted() {
    EventBus.$emit('load-type-edit', this.type)
  },
}
</script>

<style lang="stylus" scoped>
  .AmountsModal
    display block

  .Title
    modalTitle()

  .ImageWrap
    box(150px)
    margin 0 auto
</style>
